import { TitleContextWraper } from "components/admin-context";
import { Footer } from "components/footer";
import { Header } from "components/header";
import { DesktopMainMenuSC } from "components/menu/desktop-main-menu/styled";
import { MobileMenuUser } from "components/menu/mobile-menu-user";
import { connect } from "react-redux";
import { statesSelectors } from "services/redux/root/states/selectors";
import { AdminAreaWrapper, Content } from "styled/layouts";
import { MainAdmin } from "./styled";
import { MobileMainMenuSC } from "components/menu/mobile-main-menu/styled";
import { GeneralInfromationContextProvider } from "components/general-information-context-provider";
import { ThemeProvider } from "components/theme/theme-provider";
import { HelmetWrapper } from "components/helmet/helmet-wrapper";
import { RegularGlobalStyles } from "services/styled-components/regular-global-styles";
import { ToastContainer } from "react-toastify";
import { Access } from "components/check-roles/access";
import { CookiesAgreementToast } from "components/components-common/cookies-agreement-toast";
import {
    RootHelmetBodyInjector,
    RootHelmetAndScriptsBodyInjector,
} from "components/root-helmet";
import { ManagerPartition } from "views/manager";
import { PartnerPartition } from "views/partner";
import { PickCurrentLineProvider } from "components/get-active-link-context";

export enum SitePartitionType {
    Partner,
    Manager,
}

interface IInjectedProps {
    isProfileMenuClicked: boolean;
    isMainMenuClicked: boolean;
}

interface IProps extends IInjectedProps {
    adminRole: SitePartitionType;
}

export const SitePartitionWrapper = connect(state => ({
    isProfileMenuClicked: statesSelectors.getIsProfileMenuClicked(state),
    isMainMenuClicked: statesSelectors.getIsMainMenuClicked(state),
}))(
    React.memo((props: IProps) => {
        const mainMenuClicked = props.isMainMenuClicked;

        return (
            <GeneralInfromationContextProvider>
                <ThemeProvider>
                    {props.adminRole === SitePartitionType.Manager ? (
                        <RootHelmetBodyInjector />
                    ) : (
                        <RootHelmetAndScriptsBodyInjector />
                    )}
                    <RegularGlobalStyles />
                    <TitleContextWraper>
                        <HelmetWrapper>
                            <PickCurrentLineProvider>
                                <>
                                    <Content
                                        isProfileMenuClicked={
                                            props.isProfileMenuClicked
                                        }
                                        isMainMenuClicked={mainMenuClicked}
                                    >
                                        {/* эти мени появляются только в мобильной версии */}
                                        <MobileMenuUser
                                            adminRole={props.adminRole}
                                        />
                                        <MobileMainMenuSC
                                            adminRole={props.adminRole}
                                        />
                                        {/* это меню появляется в десктоп версии*/}
                                        {/* (MenuUser в десктоп версии расположенно в другом месте)  */}
                                        <DesktopMainMenuSC
                                            adminRole={props.adminRole}
                                        />
                                        <AdminAreaWrapper
                                            forAdminPanel={true}
                                            isMainMenuClicked={mainMenuClicked}
                                        >
                                            <Header />
                                            <MainAdmin>
                                                {props.adminRole ===
                                                SitePartitionType.Manager ? (
                                                    <ManagerPartition />
                                                ) : (
                                                    <PartnerPartition />
                                                )}
                                            </MainAdmin>
                                            <Footer />
                                        </AdminAreaWrapper>
                                    </Content>
                                    <ToastContainer
                                        position="top-center"
                                        newestOnTop={true}
                                    />
                                    <Access name="cookies">
                                        <CookiesAgreementToast />
                                    </Access>
                                </>
                            </PickCurrentLineProvider>
                        </HelmetWrapper>
                    </TitleContextWraper>
                </ThemeProvider>
            </GeneralInfromationContextProvider>
        );
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
