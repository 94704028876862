import { css } from "styled-components";
const fontsPath = "../assets/fonts/roboto/";

export const RobotoFonts = css`
    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}Robotolight.eot");
        src: url("${fontsPath}Robotolight.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Robotolight.woff") format("woff"),
            url("${fontsPath}Robotolight.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}Roboto.eot");
        src: url("${fontsPath}Roboto.eot?#iefix") format("embedded-opentype"),
            url("${fontsPath}Roboto.woff") format("woff"),
            url("${fontsPath}Roboto.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}Robotomedium.eot");
        src: url("${fontsPath}Robotomedium.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Robotomedium.woff") format("woff"),
            url("${fontsPath}Robotomedium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}Robotobold.woff") format("woff"),
            url("${fontsPath}Robotobold.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
`;
