import { TFunction } from "i18next";
import { PartnerAccount_authorized_partnerAccount } from "gql/types/operation-result-types";
import { PopupType } from "./popup-id";
import { ReferencePopupData } from "./block-reference/reference-popup-data";
import { ConfirmEmailPopupData } from "./confirm-email/confirm-email-popup-data";
import { InfoBlockPopupData } from "./info-block/info-block-popup-data";
import { TelegramPopupData } from "./telegram/telegram-popup-data";
import { BannerPopupData } from "./banner/banner-popup-data";
import { PartnerBanner as PartnerBanner38 } from "./custom-banners/38_1/partner-banner";
import bannerBangladeshSrc from "assets/images/banner-modal/bangladesh.jpg";
import bannerEgyptSrc from "assets/images/banner-modal/egypt.jpg";
import bannerAfropariSrc from "assets/images/banner-modal/afropari.jpg";
import bannerGoldpariSrc from "assets/images/banner-modal/goldpari.jpg";
import bannerFunpariSrc from "assets/images/banner-modal/funpari.jpg";
import bannerBangladeshResultsSrc from "assets/images/banner-modal/bangladesh-results.jpg";
import _1xPartnersBDSrc from "assets/images/banner-modal/1x-partners-bd.jpg";
import _1xPartnersUZSrc from "assets/images/banner-modal/1x-partners-uz.jpg";
import _1xPartnersTelegramIDSrc from "assets/images/banner-modal/1x-partners-telegram/id.jpg";
import _1xPartnersTelegramMYSrc from "assets/images/banner-modal/1x-partners-telegram/my.jpg";
import _1xPartnersTelegramPHSrc from "assets/images/banner-modal/1x-partners-telegram/ph.jpg";
import _1xPartnersTelegramKHSrc from "assets/images/banner-modal/1x-partners-telegram/kh.jpg";
import _1xPartnersTelegramTHSrc from "assets/images/banner-modal/1x-partners-telegram/th.jpg";
import _1xPartnersTelegramSISrc from "assets/images/banner-modal/1x-partners-telegram/si.jpg";
import _1xPartnersTelegramKRSrc from "assets/images/banner-modal/1x-partners-telegram/kr.jpg";
import _1xPartnersTelegramVNSrc from "assets/images/banner-modal/1x-partners-telegram/vn.jpg";
import _1xPartnersTelegramMMSrc from "assets/images/banner-modal/1x-partners-telegram/mm.jpg";
import _1xPartnersTelegramCNSrc from "assets/images/banner-modal/1x-partners-telegram/cn.jpg";
import _1xPartnersTelegramTWSrc from "assets/images/banner-modal/1x-partners-telegram/tw.jpg";
import _1xPartnersleagueBDSrc from "assets/images/banner-modal/league-competition/bd.jpg";
import _1xPartnersleagueUZSrc from "assets/images/banner-modal/league-competition/uz.jpg";
import {
    countryIds,
    countryGeoCodes,
} from "views/partner/main/profile/country-ids";

const {
    UZBEKISTAN,
    INDIA,
    BANGLADESH,
    TURKEY,
    LATAM_COUNTIRES_MELBET,
    LATAM_COUNTIRES_1XBET,
} = countryIds;
const { LATAM_GEO_CODES_MELBET, LATAM_GEO_CODES_1XBET } = countryGeoCodes;

const _1X_CASINO_LINK =
    "https://casaff.top/L?tag=d_3603128m_72475c_&site=3603128&ad=72475";
const _1X_TELEGRAM_LINK = "https://t.me/affiliates_1x";

export const getPopupsConfig = (
    currentGeoCountryCode: string | undefined,
    partnerAccountData: PartnerAccount_authorized_partnerAccount["mainAccountData"],
    __: TFunction,
) => {
    return [
        new ReferencePopupData({
            id: 11,
            type: PopupType.BlockReference,
            disallowedAdAgentIds: ["7", "20", "10", "48", "30", "33"],
            disallowedAffiliateIds: ["134", "142", "7009"],
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "3",
                name: "RUB",
            },
            title: __("Текущие партнерские ссылки вновь были заблокированы!"),
            /* prettier-ignore */
            description: __("Во избежание потери трафика, просим вас заменить все маркетинговые материалы на новые, как можно скорее. Ваши партнерские ссылки уже обновлены и находятся в разделе \"Маркетинговые инструменты\"."),
            infoText: __(
                "Обратите внимание, что домен рабочей партнерской ссылки должен быть:",
            ),
        }),

        new TelegramPopupData({
            id: 12,
            type: PopupType.Telegram,
            linkInPopup: "https://t.me/joinchat/AAAAAERxS0y4a4CAOX5EMA",
            timesToShow: 3,
            adAgentId: "1",
            materialGroupId: "1",
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "6",
                name: "USD",
            },
        }),
        new TelegramPopupData({
            id: 13,
            type: PopupType.Telegram,
            linkInPopup: "https://t.me/joinchat/AAAAAEMTyudFqq3oQ80RnQ",
            timesToShow: 3,
            adAgentId: "1",
            materialGroupId: "1",
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "3",
                name: "RUB",
            },
        }),
        new ConfirmEmailPopupData({
            id: 14,
            type: PopupType.ConfirmEmail,
            adAgentId: ["52", "57", "72"],
            partnerAccountData,
            currentGeoCountryCode,
        }),
        new BannerPopupData({
            id: 16,
            type: PopupType.Banner,
            src: bannerBangladeshSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "BD",
            currentGeoCountryCode,
        }),
        new BannerPopupData({
            id: 18,
            type: PopupType.Banner,
            src: bannerEgyptSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "EG",
            currentGeoCountryCode,
        }),
        new BannerPopupData({
            id: 19,
            type: PopupType.Banner,
            src: bannerAfropariSrc,
            adAgentId: "67",
            timesToShow: 1,
        }),
        new BannerPopupData({
            id: 20,
            type: PopupType.Banner,
            src: bannerGoldpariSrc,
            adAgentId: "76",
            timesToShow: 1,
        }),
        new BannerPopupData({
            id: 21,
            type: PopupType.Banner,
            src: bannerFunpariSrc,
            adAgentId: "75",
            timesToShow: 1,
        }),
        new ReferencePopupData({
            id: 26,
            type: PopupType.BlockReference,
            adAgentId: ["30", "33"],
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "6",
                name: "USD",
            },
            title: __("Текущие партнерские ссылки вновь были заблокированы!"),
            /* prettier-ignore */
            description: __("Во избежание потери трафика, просим вас заменить все маркетинговые материалы на новые, как можно скорее. Ваши партнерские ссылки уже обновлены и находятся в разделе \"Маркетинговые инструменты\"."),
            infoText: __(
                "Обратите внимание, что домен рабочей партнерской ссылки должен быть:",
            ),
        }),
        new InfoBlockPopupData({
            id: 27,
            type: PopupType.InfoBlock,
            adAgentId: "57",
            partnerAccountData,
        }),
        new BannerPopupData({
            id: 28,
            type: PopupType.Banner,
            src: bannerBangladeshResultsSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "BD",
            currentGeoCountryCode,
            timesToShow: 1,
        }),
        new BannerPopupData({
            id: 32,
            type: PopupType.CustomBanner,
            src: "",
            adAgentId: "38",
            materialGroupId: "1",
            currentGeoCountryCode,
            component: PartnerBanner38,
            showOnceADay: true,
        }),
        new ConfirmEmailPopupData({
            id: 33,
            type: PopupType.ConfirmEmail,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: ["UZ", "IN", "BD", "TR", ...LATAM_GEO_CODES_1XBET],
            partnerAccountData,
            countryIds: [
                UZBEKISTAN,
                INDIA,
                BANGLADESH,
                TURKEY,
                ...LATAM_COUNTIRES_1XBET,
            ],
            currentGeoCountryCode,
        }),
        new ConfirmEmailPopupData({
            id: 35,
            type: PopupType.ConfirmEmail,
            adAgentId: "4",
            materialGroupId: "2",
            countryCode: ["BD", "TR", ...LATAM_GEO_CODES_MELBET],
            partnerAccountData,
            countryIds: [BANGLADESH, TURKEY, ...LATAM_COUNTIRES_MELBET],
            currentGeoCountryCode,
        }),
        new BannerPopupData({
            id: 36,
            type: PopupType.Banner,
            src: _1xPartnersBDSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "BD",
            currentGeoCountryCode,
            link: _1X_CASINO_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 38,
            type: PopupType.Banner,
            src: _1xPartnersUZSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "UZ",
            currentGeoCountryCode,
            link: _1X_CASINO_LINK,
            showOnceADay: true,
        }),
        new ConfirmEmailPopupData({
            id: 39,
            type: PopupType.ConfirmEmail,
            adAgentId: "4",
            materialGroupId: "1",
            partnerAccountData,
            currentGeoCountryCode,
        }),
        new BannerPopupData({
            id: 41,
            type: PopupType.Banner,
            src: _1xPartnersTelegramIDSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "ID",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 42,
            type: PopupType.Banner,
            src: _1xPartnersTelegramMYSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "MY",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 43,
            type: PopupType.Banner,
            src: _1xPartnersTelegramPHSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "PH",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 44,
            type: PopupType.Banner,
            src: _1xPartnersTelegramKHSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "KH",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 45,
            type: PopupType.Banner,
            src: _1xPartnersTelegramTHSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "TH",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 46,
            type: PopupType.Banner,
            src: _1xPartnersTelegramSISrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "SI",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 47,
            type: PopupType.Banner,
            src: _1xPartnersTelegramKRSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "KR",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 48,
            type: PopupType.Banner,
            src: _1xPartnersTelegramVNSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "VN",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 49,
            type: PopupType.Banner,
            src: _1xPartnersTelegramMMSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "MM",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 50,
            type: PopupType.Banner,
            src: _1xPartnersTelegramCNSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "CN",
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 51,
            type: PopupType.Banner,
            src: _1xPartnersTelegramTWSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: ["TW", "HK", "MO"],
            currentGeoCountryCode,
            link: _1X_TELEGRAM_LINK,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 52,
            type: PopupType.Banner,
            src: _1xPartnersleagueBDSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "BD",
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 53,
            type: PopupType.Banner,
            src: _1xPartnersleagueUZSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: "UZ",
            currentGeoCountryCode,
            showOnceADay: true,
        }),
    ];
};
