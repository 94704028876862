import { GilroyFonts } from "./gilroy";
import { GolosTextFonts } from "./golos-text";
import { HalvarBreitschriftFonts } from "./halvar-breitschrift";
import { HelveticaNeueFonts } from "./helvetica-neue";
import { LeagueGothicFonts } from "./league-gothic";
import { MontserratFonts } from "./montserrat";
import { NotoSansFonts } from "./noto-sans";
import { OpenSansFonts } from "./open-sans";
import { PFDinTextCondProFonts } from "./pfd-in-display-pro";
import { PlayFonts } from "./play";
import { RobotoFonts } from "./roboto";
import { RobotoCondensedFonts } from "./roboto-condensed";
import { UbuntuFonts } from "./ubuntu";
import { HindFonts } from "./hind";
import { OnestFonts } from "./onest";
import { RubikFonts } from "./rubik";
import { KronaOneFonts } from "./krona-one";
import { RobotoMonoFonts } from "./roboto-mono";

export {
    GilroyFonts,
    GolosTextFonts,
    LeagueGothicFonts,
    PFDinTextCondProFonts,
    PlayFonts,
    MontserratFonts,
    RobotoFonts,
    HelveticaNeueFonts,
    UbuntuFonts,
    HalvarBreitschriftFonts,
    NotoSansFonts,
    RobotoCondensedFonts,
    OpenSansFonts,
    HindFonts,
    OnestFonts,
    RubikFonts,
    KronaOneFonts,
    RobotoMonoFonts,
};
