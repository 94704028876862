import { css } from "styled-components";
import flagsSpriteSrc from "./images/custom-flags/flags-sprite.png";

export const CustomLanguage = css`
    white-space: nowrap;

    &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 2rem;
        height: 2rem;
        background-image: url("${flagsSpriteSrc}");
        background-repeat: no-repeat;
    }

    &[data-country="FI"]::before {
        background-position: -25rem -1rem;
    }

    &[data-country="FR"]::before {
        background-position: -25rem -5rem;
    }

    &[data-country="HR"]::before {
        background-position: -9rem -21rem;
    }

    &[data-country="JP"]::before {
        background-position: -21rem -9rem;
    }

    &[data-country="RU"]::before {
        background-position: -13rem -5rem;
    }

    &[data-country="UA"]::before {
        background-position: -5rem -5rem;
    }

    &[data-country="AZ"]::before {
        background-position: -25rem -25rem;
    }

    &[data-country="BG"]::before {
        background-position: -17rem -25rem;
    }

    &[data-country="BR"]::before {
        background-position: -13rem -25rem;
    }

    &[data-country="HU"]::before {
        background-position: -5rem -21rem;
    }

    &[data-country="DE"]::before {
        background-position: -1rem -25rem;
    }

    &[data-country="TR"]::before {
        background-position: -9rem -5rem;
    }

    &[data-country="ID"]::before {
        background-position: -1rem -21rem;
    }

    &[data-country="ES"]::before {
        background-position: -25rem -13rem;
    }

    &[data-country="IT"]::before {
        background-position: -21rem -13rem;
    }

    &[data-country="CN"]::before {
        background-position: -9rem -25rem;
    }

    &[data-country="LV"]::before {
        background-position: -9rem -17rem;
    }

    &[data-country="LT"]::before {
        background-position: -13rem -17rem;
    }

    &[data-country="MK"]::before {
        background-position: -5rem -17rem;
    }

    &[data-country="MN"]::before {
        background-position: -17rem -9rem;
    }

    &[data-country="NL"]::before {
        background-position: -17rem -1rem;
    }

    &[data-country="PL"]::before {
        background-position: -5rem -13rem;
    }

    &[data-country="PT"]::before {
        background-position: -1rem -13rem;
    }

    &[data-country="RO"]::before {
        background-position: -13rem -9rem;
    }

    &[data-country="SK"]::before {
        background-position: -9rem -9rem;
    }

    &[data-country="TW"]::before {
        background-position: -9rem -1rem;
    }

    &[data-country="TH"]::before {
        background-position: -1rem -9rem;
    }

    &[data-country="UZ"]::before {
        background-position: -5rem -1rem;
    }

    &[data-country="MY"]::before {
        background-position: -17rem -5rem;
    }

    &[data-country="GB"]::before {
        background-position: -25rem -17rem;
    }

    &[data-country="VN"]::before {
        background-position: -1rem -1rem;
    }

    &[data-country="DK"]::before {
        background-position: -25rem -21rem;
    }

    &[data-country="EE"]::before {
        background-position: -25rem -9rem;
    }

    &[data-country="NO"]::before {
        background-position: -13rem -13rem;
    }

    &[data-country="SE"]::before {
        background-position: -13rem -1rem;
    }

    &[data-country="CZ"]::before {
        background-position: -5rem -25rem;
    }

    &[data-country="GR"]::before {
        background-position: -21rem -21rem;
    }

    &[data-country="IL"]::before {
        background-position: -17rem -21rem;
    }

    &[data-country="AE"]::before {
        background-position: -29rem -1rem;
    }

    &[data-country="PK"]::before {
        background-position: -1rem -5rem;
    }

    &[data-country="IR"]::before {
        background-position: -21rem -17rem;
    }

    &[data-country="IN"]::before {
        background-position: -13rem -21rem;
    }

    &[data-country="BD"]::before {
        background-position: -21rem -25rem;
    }

    &[data-country="KH"]::before {
        background-position: -21rem -5rem;
    }

    &[data-country="KR"]::before {
        background-position: -21rem -1rem;
    }

    &[data-country="RS"]::before {
        background-position: -1rem -17rem;
    }

    &[data-country="KZ"]::before {
        background-position: -17rem -17rem;
    }

    &[data-country="MM"]::before {
        background-position: -17rem -13rem;
    }

    &[data-country="PH"]::before {
        background-position: -9rem -13rem;
    }

    &[data-country="SO"]::before {
        background-position: -5rem -9rem;
    }
`;
