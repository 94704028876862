import { useTranslationList } from "hooks/use-translations-list";
import * as yup from "yup";
import { Moment, isMoment } from "moment";
import { Periods } from "components/filters/fields/period";
import { getExtentionsFromAccept } from "./helpers";
import { urlRegex, passwordRegex } from "services/validators";

export function useObjectValidations() {
    const {
        FIELD_SHOULD_BE_FILLED,
        INVALID_FORMAT,
        INVALID_FILE_EXTENSION,
        AT_LEAST_MIN_CHARACTERS,
        UP_TO_MAX_CHARACTERS,
        INVALID_PASSWORD,
        WEAK_PASSWORD,
        WEAK_PASSWORD_WITHOUT_HINT,
    } = useTranslationList();

    // actually, using objectRequiredRule and objectRule is a sort of compiler tricking (like "as"),
    // because the typing is ok, but it doesn't validate and doesn't cast
    // to cast and validate properly it should be something like this:
    // .object({id: yup.number().required(), name: yup.string().required()})
    // but we didn't noticed it because it's mostly used in dropdowns where there's no need to cast or validate

    // eslint-disable-next-line @typescript-eslint/ban-types
    const objectRequiredRule = <T extends object>() =>
        yup
            .object<T>()
            .typeError(INVALID_FORMAT)
            .required(FIELD_SHOULD_BE_FILLED);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const objectRule = <T extends object>() =>
        yup.object<T>().typeError(INVALID_FORMAT);

    const enumRule = <T extends string>() => yup.string<T>().nullable();

    const enumRuleRequired = <T extends string>() =>
        enumRule<T>().required(FIELD_SHOULD_BE_FILLED);

    const interger = yup
        .number()
        .integer(INVALID_FORMAT)
        .typeError(INVALID_FORMAT);
    const nullableInterger = yup
        .number()
        .integer(INVALID_FORMAT)
        .nullable()
        .transform(function (value, originalValue) {
            if (this.isType(value)) {
                return value;
            }
            if (!originalValue || !originalValue.trim()) {
                return null;
            }
            // we return the invalid original value
            return originalValue;
        })
        .typeError(INVALID_FORMAT);

    const emailRegex =
        /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/;
    function email(required?: boolean) {
        let emailValidation = yup.string().email(INVALID_FORMAT);

        if (required) {
            emailValidation = emailValidation.required(FIELD_SHOULD_BE_FILLED);
        }

        emailValidation = emailValidation
            .matches(emailRegex, INVALID_FORMAT)
            .lowercase();

        return emailValidation;
    }

    function pointNumber() {
        return yup
            .number()
            .nullable()
            .typeError(INVALID_FORMAT)
            .required(FIELD_SHOULD_BE_FILLED);
    }

    function messengerRules(required?: boolean) {
        const rules = {
            messenger: idNameObject().nullable(),
            messengerLogin: yup.string(),
        };

        if (required) {
            return {
                messenger: rules.messenger.required(FIELD_SHOULD_BE_FILLED),
                messengerLogin: rules.messengerLogin.required(
                    FIELD_SHOULD_BE_FILLED,
                ),
            };
        }

        return rules;
    }

    const momentSchema = yup.mixed<Moment | null | undefined>().test({
        message: INVALID_FORMAT,
        test: (value?: Moment | null) =>
            isMoment(value) || [null, undefined].includes(value),
    });

    const periodSchema = yup
        .object({
            start: momentSchema.required(FIELD_SHOULD_BE_FILLED),
            end: momentSchema.required(FIELD_SHOULD_BE_FILLED),
            interval: yup.string().oneOf(Object.values(Periods)),
        })
        .required(FIELD_SHOULD_BE_FILLED);

    const fileRule = (accept: string) =>
        yup
            .mixed<File | null>()
            .required(FIELD_SHOULD_BE_FILLED)
            .test("Is valid extension", INVALID_FILE_EXTENSION, file => {
                if (file) {
                    const fileExtension = file.name.split(".").pop();

                    return getExtentionsFromAccept(accept).includes(
                        fileExtension,
                    );
                }
                return true;
            });

    const urlSchema = yup.string().matches(urlRegex, INVALID_FORMAT);

    const passwordRule = (withoutHint = false) =>
        yup
            .string()
            .required(FIELD_SHOULD_BE_FILLED)
            .min(8, AT_LEAST_MIN_CHARACTERS)
            .max(100, UP_TO_MAX_CHARACTERS)
            .matches(passwordRegex, INVALID_PASSWORD)
            .test({
                test: async value => {
                    return !(
                        await import("@webx/password-strength")
                    ).PasswordStrength.getInfo(value).error;
                },
                message: withoutHint
                    ? WEAK_PASSWORD_WITHOUT_HINT
                    : WEAK_PASSWORD,
            });

    return {
        objectRule,
        objectRequiredRule,
        interger,
        nullableInterger,
        email,
        enumRule,
        enumRuleRequired,
        pointNumber,
        messengerRules,
        momentSchema,
        periodSchema,
        fileRule,
        urlSchema,
        passwordRule,
    };
}

export function idNameObject() {
    return yup.object({
        id: yup.number(),
        name: yup.string(),
    });
}

export function emptyField() {
    return yup.mixed().notRequired().oneOf([undefined, null]);
}
