import { css } from "styled-components";

const fontsPath = "../assets/fonts/roboto-condensed/";

export const KronaOneFonts = css`
    @font-face {
        font-family: "Krona Ona";
        src: url("${fontsPath}Kronaone.woff2") format("woff2"),
            url("${fontsPath}Kronaone.woff") format("woff");
        font-weight: 400;
        font-style: normal;
    }
`;
