import * as yup from "yup";

const urlOptionalProtocolRegex =
    /^(http(s)?:\/\/)?[\w.-]+(\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i;
// Вебсайт ни в коем случае нельзя записывать в lowercase.
// "https://www.youtube.com/channel/UCbQnK6bG2y-EBKD8JMrlOUg" !== "https://www.youtube.com/channel/ucbqnk6bg2y-ebkd8jmrloug"
export const createWebsiteRule = (errorMessage: () => string) =>
    yup
        .string()
        .matches(urlOptionalProtocolRegex, errorMessage)
        .transform(function (value) {
            if (!this.isType(value) || value === undefined) {
                return null;
            }

            if (!/^(https?|ftp):\/\//.test(value)) {
                return `http://${value}`;
            }

            return value;
        });

export const phoneRegex = /^\+\d{5,15}$/;
export const passwordRegex = /^[\w#/&]{8,100}$/;
export const urlRegex =
    /^http(s)?:\/\/[\w.-]+(\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i;
