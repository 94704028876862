import { TFunction } from "i18next";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { Rights } from "gql/types/operation-result-types";
import { Home } from "@styled-icons/fa-solid/Home/Home";
import { StyledIcon } from "@styled-icons/styled-icon";
import { Bullhorn } from "@styled-icons/fa-solid/Bullhorn/Bullhorn";
import { Bullseye } from "@styled-icons/fa-solid/Bullseye/Bullseye";
import { Users } from "@styled-icons/fa-solid/Users/Users";
import { ShareSquare } from "@styled-icons/fa-solid/ShareSquare/ShareSquare";
import { Rule } from "@styled-icons/material-outlined/Rule";
import { ChartArea } from "@styled-icons/fa-solid/ChartArea/ChartArea";
import { ChartBar } from "@styled-icons/fa-solid/ChartBar/ChartBar";
import { BarChart } from "@styled-icons/boxicons-regular/BarChart/BarChart";
import { AddressCard } from "@styled-icons/fa-regular/AddressCard/AddressCard";
import { SettingsOutline } from "@styled-icons/evaicons-outline/SettingsOutline";
import { Tools } from "@styled-icons/remix-line/Tools/Tools";
import { Settings } from "@styled-icons/feather/Settings/Settings";
import { Barcode } from "@styled-icons/icomoon/Barcode/Barcode";
import { Money } from "@styled-icons/boxicons-regular/Money";
import { Payments } from "@styled-icons/material/Payments";
import { _1XBET_PARTNERS } from "server/partner-programs";

interface IMenuGroupLink {
    to: string;
    Icon: StyledIcon;
    title: string;
    isHidden?: boolean;
}

export interface IMenuGroup {
    groupTitle: string;
    Icon: StyledIcon;
    links: IMenuGroupLink[];
    isHidden?: boolean;
}

interface IMenuGroups {
    production: IMenuGroup[];
    develop: IMenuGroup[];
}

export const useMenuGroups = (__: TFunction) => {
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const isHiddenPayments = !(
        permissions?.advertising &&
        (permissions.rights.includes(Rights.CREATE_ADVERSITING_PAYMENT) ||
            permissions.rights.includes(Rights.EDIT_ADVERSITING_PAYMENT))
    );

    const menuGroups: IMenuGroups = {
        production: [
            {
                groupTitle: __("Главное меню"),
                Icon: Home,
                links: [
                    {
                        to: "/agent",
                        Icon: Home,
                        title: __("Главная"),
                    },
                ],
            },
            {
                groupTitle: __("Реклама"),
                Icon: Money,
                isHidden: isHiddenPayments,
                links: [
                    {
                        to: "/agent/advertising/payments",
                        Icon: Payments,
                        title: __("Рекламные выплаты"),
                    },
                    {
                        to: "/agent/advertising/fin-plans",
                        Icon: Payments,
                        title: __("Фин план"),
                    },
                ],
            },
            {
                groupTitle: __("Отчеты"),
                Icon: BarChart,
                links: [
                    {
                        to: "/agent/reports/player-activity",
                        Icon: AddressCard,
                        title: __("Отчет активности"),
                    },
                ],
            },
        ],
        develop: [
            {
                groupTitle: __("Главное меню"),
                Icon: Home,
                links: [
                    {
                        to: "/agent",
                        Icon: Home,
                        title: __("Главная"),
                    },
                    {
                        to: "/agent/main/partner-list",
                        Icon: Users,
                        title: __("Список партнеров"),
                    },
                    {
                        to: "/agent/main/postbacks",
                        Icon: ShareSquare,
                        title: "Postbacks",
                    },
                    {
                        to: "/",
                        Icon: Rule,
                        title: __("Правила и условия"),
                    },
                    {
                        to: "/agent/main/partners-groups",
                        Icon: Users,
                        title: __("Партнерские группы"),
                    },
                    {
                        to: "/agent/main/commission-groups",
                        Icon: Users,
                        title: __("Комиссионные группы"),
                    },
                ],
            },
            {
                groupTitle: __("Отчеты"),
                Icon: BarChart,
                links: [
                    {
                        to: "/agent/reports/quick",
                        Icon: ChartBar,
                        title: __("Краткий суммарный"),
                    },
                    {
                        to: "/agent/reports/full",
                        Icon: ChartArea,
                        title: __("Полный"),
                    },
                    {
                        to: "/agent/reports/player-activity",
                        Icon: AddressCard,
                        title: __("Отчет активности"),
                    },
                    {
                        to: "/",
                        Icon: Barcode,
                        title: __("По промо-материалам"),
                    },
                    {
                        to: "/",
                        Icon: AddressCard,
                        title: __("По игрокам"),
                    },
                ],
            },
            {
                groupTitle: __("Маркетинговые инструменты"),
                Icon: Tools,
                links: [
                    {
                        to: "/agent/marketing/campaigns",
                        Icon: Bullhorn,
                        title: __("Кампании"),
                    },
                    {
                        to: "/",
                        Icon: Bullseye,
                        title: __("Медиа"),
                    },
                ],
            },
            {
                groupTitle: __("Платежи"),
                Icon: Money,
                links: [
                    {
                        to: "/agent/payments/payment-history",
                        Icon: Money,
                        title: __("История выплат"),
                    },
                    {
                        to: "/agent/payments/invoice",
                        Icon: Money,
                        title: __("Инвойсы партнеров"),
                    },
                ],
            },
            {
                groupTitle: __("Реклама"),
                Icon: Money,
                isHidden: isHiddenPayments,
                links: [
                    {
                        to: "/agent/advertising/payments",
                        Icon: Payments,
                        title: __("Рекламные выплаты"),
                    },
                    {
                        to: "/agent/advertising/fin-plans",
                        Icon: Payments,
                        title: __("Фин план"),
                    },
                ],
            },
            {
                groupTitle: __("Настройки"),
                Icon: Settings,
                links: [
                    {
                        to: "/",
                        Icon: SettingsOutline,
                        title: __("Настройки профиля"),
                    },
                    {
                        to: "/agent/settings/content",
                        Icon: SettingsOutline,
                        title: __("Редактировать тексты"),
                    },
                    {
                        to: "/agent/settings/user-list",
                        Icon: Users,
                        title: __("Список пользователей"),
                    },
                ],
            },
        ],
    };

    return menuGroups;
};
