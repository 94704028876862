const UZBEKISTAN = 192;
const INDIA = 71;
const BANGLADESH = 19;
const TURKEY = 190;

const URUGUAY = 193;
const ARGENTINA = 14;
const BOLIVIA = 28;
const BRAZIL = 31;
const VENEZUELA = 41;
const GUATEMALA = 50;
const HONDURAS = 56;
const DOMINICAN_REPUBLIC = 65;
const COLOMBIA = 91;
const COSTA_RICA = 95;
const MEXICO = 120;
const NICARAGUA = 134;
const PARAGUAY = 144;
const PERU = 145;
const PUERTO_RICO = 149;
const EL_SALVADOR = 154;
const CHILE = 205;
const ECUADOR = 209;
const PANAMA = 142;
const TURKS_AND_CAICOS_ISLANDS = 189;
const BRITISH_VIRGIN_ISLANDS = 32;
const HAITI = 45;
const GRENADA = 58;
const ANGUILLA = 10;
const GUADELOUPE = 49;
const BAHAMAS = 18;
const LATAM_COUNTIRES_MELBET = [
    URUGUAY,
    ARGENTINA,
    BOLIVIA,
    BRAZIL,
    VENEZUELA,
    GUATEMALA,
    HONDURAS,
    DOMINICAN_REPUBLIC,
    COLOMBIA,
    COSTA_RICA,
    MEXICO,
    NICARAGUA,
    PARAGUAY,
    PERU,
    PUERTO_RICO,
    EL_SALVADOR,
    CHILE,
    ECUADOR,
];

const LATAM_COUNTIRES_1XBET = [
    URUGUAY,
    ARGENTINA,
    BOLIVIA,
    BRAZIL,
    VENEZUELA,
    GUATEMALA,
    HONDURAS,
    DOMINICAN_REPUBLIC,
    COLOMBIA,
    COSTA_RICA,
    MEXICO,
    NICARAGUA,
    PERU,
    EL_SALVADOR,
    CHILE,
    ECUADOR,
    PANAMA,
    TURKS_AND_CAICOS_ISLANDS,
    BRITISH_VIRGIN_ISLANDS,
    HAITI,
    GRENADA,
    ANGUILLA,
    GUADELOUPE,
    BAHAMAS,
];

export const countryIds = {
    UZBEKISTAN,
    INDIA,
    BANGLADESH,
    TURKEY,
    LATAM_COUNTIRES_MELBET,
    LATAM_COUNTIRES_1XBET,
};

const LATAM_GEO_CODES_MELBET = [
    "UY",
    "AR",
    "BO",
    "BR",
    "VE",
    "GT",
    "HN",
    "DO",
    "CO",
    "CR",
    "MX",
    "NI",
    "PY",
    "PE",
    "PR",
    "SV",
    "CL",
    "EC",
];

const LATAM_GEO_CODES_1XBET = [
    "UY",
    "AR",
    "BO",
    "BR",
    "VE",
    "GT",
    "HN",
    "DO",
    "CO",
    "CR",
    "MX",
    "NI",
    "PE",
    "SV",
    "CL",
    "EC",
    "PA",
    "TC",
    "VG",
    "HT",
    "GD",
    "AI",
    "BS",
];

export const countryGeoCodes = {
    LATAM_GEO_CODES_MELBET,
    LATAM_GEO_CODES_1XBET,
};
