import { TFunction } from "react-i18next";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { t as __ } from "components/language-provider";
import { ErrorType } from "../../../server/errors/error-type";
import { RequestError } from "../../../server/errors/request-error";
import { GraphQLErrors } from "@apollo/client/errors";

type ErrorWithCode = RequestError & {
    data: {
        code: ErrorType;
    };
};

export const errorHandler = async (
    error: Error | { graphQLErrors: GraphQLErrors } | RequestError,
    requestErrorHandler?: (
        error: ErrorWithCode,
    ) => string | true | void | ReturnType<TFunction>,
) => {
    // обрабатываем только graphQLErrors не обращая внимания на NetworkError
    // TODO: когда у нас вообще может быть так что в коллекции graphQLErrors больше чем 1 ошибка?
    if ("graphQLErrors" in error) {
        for (const gError of error.graphQLErrors) {
            if (RequestError.isInstance(gError)) {
                await errorHandler(gError, requestErrorHandler);
            }
        }
        return;
    }

    // эта часть работает на втором вызове этой же функции,
    // когда мы вызываем это для каждого элемента массива graphQLErrors
    if (RequestError.isInstance(error)) {
        const handlerAnswer =
            requestErrorHandler && requestErrorHandler(error as any);
        let errorText: string | undefined;
        if (handlerAnswer === true) {
            return;
        } else if (_.isString(handlerAnswer)) {
            errorText = handlerAnswer;
        } else {
            switch ((error as any).data.code) {
                case ErrorType.ACCESS_DENIED:
                    if (
                        (error as any).data?.details?.detailedCode ===
                        "INVALID_LOGIN_OR_PASSWORD"
                    ) {
                        errorText = __("Неверное имя пользователя или пароль");
                    } else {
                        errorText = __("Доступ запрещён");
                    }
                    break;
                case ErrorType.TOO_MANY_REQUESTS:
                    errorText = __(
                        "Слишком много запросов. Пожалуйста, повторите запрос позже",
                    );
                    break;
                case ErrorType.NO_ATTEMPTS_LEFT:
                    errorText = __(
                        "У вас не осталось попыток. Пожалуйста, повторите запрос позже",
                    );
                    break;
                case ErrorType.UNEXPECTED_ERROR:
                    errorText = __("Произошла неизвестная ошибка");
                    break;
                default:
                    log.error("Unhandled RequestError", error as Error);
                    break;
            }
            log.error("Unhandled error at errorHandler", error as Error);
        }
        if (errorText) {
            toast.error(errorText, { autoClose: 15000 });
        }
    } else {
        // просто, хотя бы, логируем такую ошибку
        // т.к. по нашей политике мы не должны оповещать пользователя никак
        // при таких ошибках
        log.error("Unhandled error not  RequestError type", error as Error);
    }
};
