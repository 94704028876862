import { useCallback, useState } from "react";
import {
    GetPlayersReport,
    GetPlayersReportVariables,
    PlayersReportFilter,
} from "gql/types/operation-result-types";
import { useUser } from "hooks/query/use-user";
import { getLanguageCode, useLanguage } from "components/language-provider";
import { usePooling } from "hooks/use-pooling";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { savePartnerReport } from "../../services/save-repot-query";
import GET_PLAYERS_REPORT from "../query.gql";
import { tableData } from "./get-table-header";

export const useSavePlayerReport = (
    values: PlayersReportFilter,
    format: string,
) => {
    const [saving, setSaving] = useState(false);

    const { user } = useUser();
    const [__] = useTranslation();
    const { language } = useLanguage();
    const culture = getLanguageCode(language.code);

    const [loadPlayerReport, PlayerReportResult] = useLazyQuery<
        GetPlayersReport,
        GetPlayersReportVariables
    >(GET_PLAYERS_REPORT, {
        fetchPolicy: "cache-and-network",
        onCompleted: async data => {
            const hash = g(
                data,
                "authorized",
                "partner",
                "reports",
                "playersReport",
                "hash",
            );

            try {
                await savePartnerReport({
                    url: "PartnerReports/PlayersReportExport",
                    hash,
                    tablaData: tableData(
                        {
                            user,
                            values,
                            reportFormat: format,
                            culture,
                        },
                        __,
                    ),
                });
            } catch (e) {
                //
            }
            setSaving(false);
        },
    });

    const status = g(
        PlayerReportResult.data,
        "authorized",
        "partner",
        "reports",
        "playersReport",
        "status",
    );
    usePooling(PlayerReportResult, status, 3000);

    const save = useCallback(() => {
        setSaving(true);
        void loadPlayerReport({
            variables: {
                filter: { ...values, methood: "save" },
            },
        });
    }, [loadPlayerReport, values]);

    return {
        saving,
        save,
    };
};
