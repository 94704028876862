import { TFunction } from "i18next";
import {
    getCurrencyById,
    getClassNameById,
    getVerifiStatTextById,
} from "services/project-utils";
import { IReactTableColumn } from "components/react-table/column";

export const getColumns = (__: TFunction): IReactTableColumn[] => [
    {
        Header: __("Номер игрока"),
        accessor: "id",
        disabled: true,
        minWidth: 80,
    },
    {
        Header: __("ФИО"),
        accessor: "fio",
        minWidth: 120,
    },
    {
        Header: __("Дата регистрации"),
        accessor: "dateReg",
        dataType: "date",
        minWidth: 80,
    },
    {
        Header: __("Количество пополнений"),
        accessor: "countPays",
        minWidth: 80,
    },
    {
        Header: __("Количество мультов xguid"),
        accessor: "doubl",
        minWidth: 80,
        Cell: ({ value }) => value || 0,
    },
    {
        Header: __("Количество мультов по кошельку"),
        accessor: "countMultWallet",
        minWidth: 80,
    },
    {
        Header: __("Количество активных дней"),
        accessor: "countActiveDays",
        minWidth: 80,
    },
    {
        Header: __("Количество дней (сумма приёмов < 10%)"),
        accessor: "countSuspiciousDays",
        minWidth: 100,
    },
    {
        Header: __("Статус документов"),
        accessor: "verifiStatId",
        minWidth: 120,
        Cell: ({ value }) => getVerifiStatTextById(__, value),
    },
    {
        Header: __("Комментарий"),
        accessor: "comment",
        dataType: "boolean",
        minWidth: 80,
    },
    {
        Header: __("Класс пользователя"),
        accessor: "classNameId",
        minWidth: 120,
        Cell: ({ value }) => getClassNameById(__, value),
    },
    {
        Header: __("Сумма пополнений"),
        accessor: "sumPays",
        minWidth: 80,
    },
    {
        Header: __("Сумма выводов"),
        accessor: "sumOut",
        minWidth: 80,
    },
    {
        Header: __("Сумма пополнений за последний месяц"),
        accessor: "idPaysMounth",
        minWidth: 80,
    },
    {
        Header: __("Валюта"),
        accessor: "code",
        minWidth: 80,
        Cell: ({ value }) => getCurrencyById(value),
    },
];
