import { TitleContext } from "components/admin-context";
import { AndroidLogo } from "components/android-app/android-logo";
import { HeaderItem } from "components/android-app/android-logo/styled";
import { AskQuestions } from "components/header/components/ask-questions";
import { LanguageSwitcher } from "components/components-common/language/language-switcher";
import { useDefinedContext } from "hooks/use-context-exist";
import { useContext } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { PartnersProgramGeneralInformationContext } from "views";
import { ProfileMenu } from "./components/profile-menu";
import { UpdateStatistic } from "./components/update-statistic";
import {
    AuxiliaryBlock,
    CurrentSection,
    CurrentSectionItem,
    HeaderSC,
    MobileButtonsWrapper,
    MobileLogo,
    HeaderItemLanguage,
} from "./styled";
import { MenuBurger } from "components/menu/main-menu/menu-burger";
import { MobileMenuUserToggle } from "components/menu/mobile-menu-user/mobile-menu-user-toggle";
import { PARTNERS_1XBET } from "server/partner-programs";
import { KeyboardArrowRight } from "@styled-icons/material/KeyboardArrowRight";
import { useUser } from "hooks/query/use-user";

export const Header = compose(withRouter)(
    React.memo(() => {
        const { pageTitle, additionalPageTitle } = useContext(TitleContext);
        const partnersGeneralInformation = useDefinedContext(
            PartnersProgramGeneralInformationContext,
        );
        const { user } = useUser();

        const canShowAskQuestionsForPartners =
            partnersGeneralInformation.partnersProgramId === PARTNERS_1XBET;
        const canShowForUsers =
            user?.name === "demomember" && !__ENVIRONMENT__.production;
        const canShowAskQuestions =
            canShowAskQuestionsForPartners || canShowForUsers;

        return (
            <HeaderSC>
                <MobileLogo />
                <MobileButtonsWrapper>
                    <MobileMenuUserToggle />
                    <MenuBurger />
                </MobileButtonsWrapper>
                {!additionalPageTitle ? (
                    <CurrentSection>
                        <CurrentSectionItem active>
                            {pageTitle}
                        </CurrentSectionItem>
                    </CurrentSection>
                ) : (
                    <CurrentSection>
                        <CurrentSectionItem>{pageTitle}</CurrentSectionItem>
                        <KeyboardArrowRight size="40" />
                        <CurrentSectionItem active>
                            {additionalPageTitle}
                        </CurrentSectionItem>
                    </CurrentSection>
                )}
                <AuxiliaryBlock>
                    {partnersGeneralInformation.androidAppLinks && (
                        <HeaderItem>
                            <AndroidLogo
                                size="22px"
                                link={
                                    partnersGeneralInformation.androidAppLinks
                                }
                                isAbleToHide={true}
                            />
                        </HeaderItem>
                    )}
                    {canShowAskQuestions && <AskQuestions />}
                    <UpdateStatistic />
                    <HeaderItemLanguage>
                        <LanguageSwitcher
                            textColor={
                                partnersGeneralInformation.stylesTheme.textColor
                            }
                            bgColor={
                                partnersGeneralInformation.stylesTheme
                                    .languageSwitcherBG
                            }
                            accentColor={
                                partnersGeneralInformation.stylesTheme
                                    .accentColor
                            }
                            sectionAdminPartner={true}
                            screen="desktop"
                        />
                    </HeaderItemLanguage>
                    <ProfileMenu />
                </AuxiliaryBlock>
            </HeaderSC>
        );
    }),
);
