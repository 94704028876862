import { Logo } from "components/logo";
import { media } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { ButtonColored } from "styled/button";
import { ColoredNavLink } from "styled/link";
import { rtl } from "services/styled-components/rtl";
import {
    LanguageSwitcherSC,
    LanguageList,
} from "components/components-common/language/language-switcher/styled";

export const HeaderItemSC = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    flex: 1 0 auto;
    @media (max-width: 1270px) {
        padding: 0 10px;
    }
    ${media.mdMax`
        font-size: 12px;
    `}
`;

export interface IMenuBurger {
    clicked: boolean;
}

export const MenuBurger = styled.div<IMenuBurger>`
    pointer-events: all;
    z-index: 100;
    cursor: pointer;
    padding: 0 15px;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        margin: 0 auto;
        position: relative;
        transition-duration: 0s !important;
        transition-delay: 0.2s !important;
        background-color: ${props => props.theme.accentColor};
    }
    span:before,
    span:after {
        position: absolute;
        content: "";
        margin-top: 0;
        background-color: ${props => props.theme.accentColor};
    }
    span,
    span:before,
    span:after {
        width: 30px;
        height: 4px;
        display: block;
        pointer-events: none;
        border-radius: 2px;
    }
    span:before {
        margin-top: -10px;
        transition-property: margin, transform !important;
        transition-duration: 0.2s !important;
        transition-delay: 0.2s, 0s !important;
    }
    span:after {
        margin-top: 10px;
        transition-property: margin, transform !important;
        transition-duration: 0.2s !important;
        transition-delay: 0.2s, 0s !important;
    }

    ${props =>
        props.clicked &&
        css`
            span {
                background-color: rgba(255, 255, 255, 0);
                transition-delay: 0s, 0.2s !important;
            }
            span::before {
                margin-top: 0;
                transform: rotate(-45deg) !important;
                transition-delay: 0s, 0.2s !important;
            }
            span::after {
                margin-top: 0;
                transform: rotate(45deg) !important;
                transition-delay: 0s, 0.2s !important;
            }
        `}
`;

export const CurrentSection = styled.div`
    font-size: 20px;
    display: flex;
    align-items: center;
    ${media.mdMax`
        ${rtl`margin-left: 20px;`};
    `}
    @media (max-width: 1270px) {
        display: none;
    }
`;

export const CurrentSectionItem = styled.span<{ active?: boolean }>`
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props => (props.active ? props.theme.accentColor : "#ffffff")};
    ${props =>
        props.active &&
        css`
            box-shadow: inset 0 -4px 0 0 ${props.theme.accentColor};
        `};
`;

export const AuxiliaryBlock = styled.div`
    pointer-events: all;
    position: relative;
    display: flex;
    ${rtl`margin-left: auto;`};
    ${media.smMax`
        display: none;
    `}
    ${media.smMinmdMax`
        ${LanguageSwitcherSC} {
            flex-direction: row;
            font-size: 12px;
            padding: 0;
        }
        ${LanguageList} {
            position: absolute;
            top: 100%;
            right: 0;
            width: 350px;
        }
    `}
`;

export const AuxiliaryBlockButton = styled.div`
    ${HeaderItemSC};
    ${ButtonColored}, ${ColoredNavLink} {
        color: ${props => props.theme.buttons.default.color};
        font-size: 12px;
        padding: 0.5em 0.8em;
        & svg {
            ${rtl`margin-right: 5px;`};
        }
    }

    ${media.smMax`
        display: block;
        padding: 0;
        ${ColoredNavLink} {
            border-radius: 0;
            width: 100%;
            padding: 15px;
            text-align: left;
            display: flex;
            align-items: center;
            font-size: 14px;
            & svg {
                width: 20px;
                height: 20px;
                ${rtl`margin-right: 15px;`};
            }
        }
    `}
`;

export const HeaderSC = styled.header`
    display: flex;
    background-color: ${({ theme }) =>
        theme.clientSideStyles?.headerBgColor || theme.backgroundColor};
    z-index: 5;
    height: 76px;
    flex: 0 0 auto;
    ${media.smMax`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        justify-content: space-between;
    `}
`;

export const MobileLogo = styled(Logo)`
    display: none;
    ${media.smMax`
        display: flex;
        align-items: center;
        padding: 15px;
    `}
`;

export const MobileButtonsWrapper = styled.div`
    display: flex;
`;

export const HeaderItemLanguage = styled.div`
    ${HeaderItemSC};
    padding: 0;
`;
