import { Times } from "@styled-icons/fa-solid/Times/Times";
import { Utils } from "@webx/utils";
import React, { ReactElement, ReactNode } from "react";
import ReactModal from "react-modal";
import { ModalSize } from "./modal-size";
import { ModalStyle } from "./modal-style";
import {
    StyledClose,
    StyledInner,
    StyledRootWrapper,
    StyledWrapper,
} from "./styled";
import { StyledCloseWrapp } from "components/overlays/styled";
if (Utils.browser.hasWindow()) {
    ReactModal.setAppElement("#root");
}

export const ModalContext = React.createContext<{ onRequestClose: () => void }>(
    {
        onRequestClose: () => {
            return;
        },
    },
);

interface IInjectedProps {}

export interface IModalProps extends IInjectedProps, ReactModal.Props {
    onRequestClose: () => void;
    children: ReactNode;
    modalSize?: ModalSize;
    modalStyle?: ModalStyle;
    customMaxWidth?: number;
    preventCloseWrapper?: boolean;
    customClose?: ReactElement;
}

export const ModalNoStyles = React.memo((props: IModalProps) => {
    // const stopProgogation = useCallback(e => e.stopPropagation(), []);

    const className = `content ${props.className || ""}
        ${
            props.modalSize
                ? "size-" + ModalSize[props.modalSize].toLowerCase()
                : ""
        }
        ${
            props.modalStyle
                ? "style-" + ModalStyle[props.modalStyle].toLowerCase()
                : ""
        }`;
    const overlayClassName = `overlay ${props.overlayClassName || ""}`;
    return (
        <>
            <ReactModal
                closeTimeoutMS={200}
                {...props}
                className={className}
                overlayClassName={overlayClassName}
            >
                <ModalContext.Provider
                    value={{ onRequestClose: props.onRequestClose }}
                >
                    <StyledRootWrapper>
                        <StyledWrapper>
                            {props.children}
                            {!props.preventCloseWrapper && (
                                <StyledCloseWrapp
                                    onClick={props.onRequestClose}
                                />
                            )}
                        </StyledWrapper>
                    </StyledRootWrapper>
                </ModalContext.Provider>
            </ReactModal>
        </>
    );
}) as React.ComponentType<Subtract<IModalProps, IInjectedProps>>;

export const Modal = React.memo((props: IModalProps) => {
    const { children, customClose, ...rest } = props;
    return (
        <>
            <ModalNoStyles {...rest}>
                <StyledInner
                    modalStyle={props.modalStyle || ModalStyle.Primary}
                    modalSize={props.modalSize || ModalSize.Default}
                >
                    <StyledClose
                        data-close-button="true"
                        onClick={props.onRequestClose}
                    >
                        {customClose || <Times size={15} />}
                    </StyledClose>
                    {children}
                </StyledInner>
            </ModalNoStyles>
        </>
    );
}) as React.ComponentType<Subtract<IModalProps, IInjectedProps>>;
