import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    PartnerInformation,
} from "gql/types/operation-result-types";
import { INotificationPopupState } from "../notification-popup";
import { PopupType } from "./popup-id";

export class PopupData {
    constructor(
        public id: number,
        public type: PopupType,
        public showPopupOnceDay?: boolean,
        public linkInPopup?: string,
        public currency?: GetCurrencies_authorized_partnerAndManager_data_currencies,
        public order?: number,
    ) {}

    public show(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        information: INotificationPopupState & PartnerInformation,
    ): boolean {
        return false;
    }
}
