import arrowBottomSmall from "./images/arrow-bottom-small.svg";
import flags from "./images/flags-sprite.png";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { linkCss } from "styled/link";
import { lighten } from "polished";
import { rtl } from "services/styled-components/rtl";

/* min-width: 120px; */
const minWidth = 70;

interface ILanguageSwitcherSCProps {
    opened: boolean;
}

export const LanguageActiveWrapper = styled.div<
    ILanguageSwitcherSCProps & { textColor: string }
>`
    display: flex;
    height: 50px;
    align-items: center;

    &::after {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        padding: 0.3125em 0.625em;
        mask: url(${arrowBottomSmall}) no-repeat 50% 50%;
        background-color: ${props => props.textColor};
        transition: all 0.3s ease;
        ${props =>
            props.opened &&
            css`
                transform: rotate(-180deg);
            `}
    }
`;

export const LanguageSwitcherSC = styled.div<
    ILanguageSwitcherSCProps & {
        textColor: string;
        accentColor: string;
        sectionAdminPartner?: boolean;
        screen: "mobile" | "desktop";
    }
>`
    cursor: pointer;
    z-index: 20;
    pointer-events: all;
    margin-right: 0;
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
    min-height: 50px;
    font-weight: 400;
    font-size: 14px;
    color: ${props => props.textColor};

    &:hover ${/* sc-sel */ LanguageActiveWrapper}::after {
        ${props =>
            props.opened
                ? css`
                      transform: rotate(-180deg);
                  `
                : css`
                      transform: rotate(0deg);
                  `}
    }

    ${props =>
        props.sectionAdminPartner &&
        css`
            height: 100%;
            padding-left: 15px;
            padding-right: 15px;
            ${props.opened &&
            css`
                box-shadow: inset 0 -4px 0 0 ${props.accentColor};
            `}
        `}

    ${props =>
        props.screen === "mobile" &&
        css`
            flex-direction: column;
            font-size: 16px;
        `}
`;

// flags sprite calculation
// x% = .407332 + (4.684318 * i)
// y% = .851064 + (9.787236 * j)
// i - flag's nuber from 0, horizontal
// j, the same, vertical
// copy to calculator, and don't forget to press enter
export const Language = css`
    white-space: nowrap;

    span {
        /* display: inline-block; */
        display: none;
        ${rtl`margin-left: 5px;`};
        ${rtl`margin-right: 2px;`};
    }

    &::after {
        content: attr(data-title);
        display: inline-block;
        vertical-align: middle;
        ${rtl`margin-inline-start: 5px;`}
        text-transform: uppercase;
        width: 21px;
    }

    &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        background-image: url("${flags}");
        background-repeat: no-repeat;
        background-size: 2438.1%;
        background-position: 100% 100%;
    }

    &:hover::before {
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.6);
    }

    &[data-country="FI"]::before {
        background-position: 37.8819% 0.851064%;
    }

    &[data-country="FR"]::before {
        background-position: 42.5662% 0.851064%;
    }

    &[data-country="HR"]::before {
        background-position: 56.6191% 0.851064%;
    }

    &[data-country="CS"]::before {
        background-position: 70.6721% 0.851064%;
    }

    &[data-country="JP"]::before {
        background-position: 33.1976% 10.6383%;
    }

    &[data-country="RU"]::before {
        background-position: 37.8819% 20.4255%;
    }

    &[data-country="UA"]::before {
        background-position: 42.5662% 20.4255%;
    }

    &[data-country="AZ"]::before {
        background-position: 56.5662% 20.4255%;
    }

    &[data-country="BG"]::before {
        background-position: 51.9348% 30.2128%;
    }

    &[data-country="BR"]::before {
        background-position: 70.6721% 30.2128%;
    }

    &[data-country="HU"]::before {
        background-position: 14.4603% 40%;
    }

    &[data-country="DE"]::before {
        background-position: 75.3564% 40%;
    }

    &[data-country="TR"]::before {
        background-position: 42.5662% 49.7872%;
    }

    &[data-country="ID"]::before {
        background-position: 61.3035% 49.7872%;
    }

    &[data-country="ES"]::before {
        background-position: 89.4094% 49.7872%;
    }

    &[data-country="IT"]::before {
        background-position: 94.0937% 49.7872%;
    }

    &[data-country="CN"]::before {
        background-position: 42.5662% 59.5745%;
    }

    &[data-country="LV"]::before {
        background-position: 94.0937% 59.5745%;
    }

    &[data-country="US"]::before {
        background-position: 0.407332% 69.3617%;
    }

    &[data-country="LT"]::before {
        background-position: 14.4603% 69.3617%;
    }

    &[data-country="MK"]::before {
        background-position: 42.5662% 69.3617%;
    }

    &[data-country="MN"]::before {
        background-position: 94.0937% 69.3617%;
    }

    &[data-country="NL"]::before {
        background-position: 33.1976% 79.1489%;
    }

    &[data-country="PL"]::before {
        background-position: 98.778% 79.1489%;
    }

    &[data-country="PT"]::before {
        background-position: 0.407332% 88.9362%;
    }

    &[data-country="RO"]::before {
        background-position: 14.4603% 88.9362%;
    }

    &[data-country="SK"]::before {
        background-position: 0.407332% 98.7234%;
    }

    &[data-country="TW"]::before {
        background-position: 89.4094% 98.7234%;
    }

    &[data-country="TH"]::before {
        background-position: 42.5662% 98.7234%;
    }

    &[data-country="UZ"]::before {
        background-position: 14.4603% 0.851064%;
    }

    &[data-country="MY"]::before {
        background-position: 51.9348% 69.3617%;
    }

    &[data-country="GB"]::before {
        background-position: 5.09165% 40%;
    }

    &[data-country="VN"]::before {
        background-position: 28.5132% 40%;
    }

    &[data-country="DK"]::before {
        background-position: 14.4603% 49.7872%;
    }

    &[data-country="EE"]::before {
        background-position: 5.09165% 10.6383%;
    }

    &[data-country="NO"]::before {
        background-position: 51.9348% 79.1489%;
    }

    &[data-country="SE"]::before {
        background-position: 84.7251% 0.851064%;
    }

    &[data-country="CZ"]::before {
        background-position: 70.6721% 0.851064%;
    }

    &[data-country="GR"]::before {
        background-position: 5.09165% 49.7872%;
    }

    &[data-country="IL"]::before {
        background-position: 51.9348% 49.7872%;
    }

    &[data-country="AE"]::before {
        background-position: 65.9878% 20.4255%;
    }

    &[data-country="PK"]::before {
        background-position: 70.672102% 79.148952%;
    }

    &[data-country="IR"]::before {
        background-position: 75.3564% 49.7872%;
    }

    &[data-country="IN"]::before {
        background-position: 56.6191% 49.7872%;
    }

    &[data-country="BD"]::before {
        background-position: 14.4603% 30.2128%;
    }

    &[data-country="KH"]::before {
        background-position: 9.776% 59.5745%;
    }

    &[data-country="KR"]::before {
        background-position: 19.1446% 10.6383%;
    }

    &[data-country="RS"]::before {
        background-position: 89.409374% 88.936188%;
    }

    &[data-country="KZ"]::before {
        background-position: 5.09165% 59.5745%;
    }

    &[data-country="MM"]::before {
        background-position: 0.407332% 79.1489%;
    }

    &[data-country="PH"]::before {
        background-position: 33.1976% 0.851064%;
    }

    &[data-country="SO"]::before {
        background-position: 14.460286% 98.723424%;
    }

    &[data-country="TJ"]::before {
        background-position: 33.1976% 98.723424%;
    }
`;

const numberOfLanguigesInARowOnDesktop = 5;
const numberOfLanguigesInARowOnMobile = 3;
export const LanguageList = styled.ul<
    ILanguageSwitcherSCProps & {
        textColor: string;
        bgColor: string;
        accentColor: string;
        textColorHover?: string;
        screen: "mobile" | "desktop";
        children: JSX.Element[];
        listHeight?: string;
    }
>`
    position: absolute;
    top: 100%;
    inset-inline-start: auto;
    inset-inline-end: 0;
    opacity: 0;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
        0 2px 10px 0 rgba(34, 36, 38, 0.15);
    max-height: 0;
    overflow: hidden;
    transition: display 0s linear 0.3s, opacity 0.3s linear,
        max-height 0.3s linear;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    ${props =>
        props.children.length >= numberOfLanguigesInARowOnDesktop
            ? "width: 350px;"
            : `width: ${minWidth * props.children.length}px;`}
    background-color: ${props => props.bgColor};

    ${props =>
        props.opened &&
        css`
            /* display: block; */
            max-height: ${props.listHeight ? props.listHeight : "1000px"};
            opacity: 1;
            transition-delay: 0s;
        `}
    li {
        width: ${minWidth}px;

        a {
            ${Language};
            color: ${props => props.textColor};
            display: block !important;
            padding: 10px !important;
            text-align: left;
            border-bottom: 1px solid ${props => lighten(0.1, props.bgColor)};

            ${props =>
                rtl`border-right: 1px solid ${lighten(0.1, props.bgColor)};`}
            &:hover {
                background-color: ${props => props.accentColor};
                color: ${props =>
                    props.textColorHover
                        ? props.textColorHover
                        : props.textColor};
            }
        }
    }

    ${props =>
        props.screen === "mobile" &&
        css`
            position: relative;
            width: 100%;
            top: auto;
            right: auto;
            left: auto;

            & li {
                ${() =>
                    props.children.length >= numberOfLanguigesInARowOnMobile
                        ? "width: calc(100% / 3);"
                        : ""}
            }
        `}
`;

export const Active = css<{ accentColor: string }>`
    color: ${props => props.accentColor};

    &::before {
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.6);
    }
`;

export const LanguageActive = styled.a<
    ILanguageSwitcherSCProps & {
        accentColor: string;
        countryFontWeight?: number;
        screen: "mobile" | "desktop";
    }
>`
    position: relative;
    ${props =>
        props.countryFontWeight &&
        css`
            font-weight: ${props.countryFontWeight};
        `}
    cursor: pointer;

    ${Language}
    &::after {
        ${rtl`margin-right: 5px;`}
    }

    ${/* sc-sel */ LanguageSwitcherSC}:hover & {
        ${Active}
    }

    ${props => props.opened && Active}

    ${props =>
        props.screen === "mobile" &&
        css`
            ${rtl`padding-left: 15px;`};

            &:after {
                ${rtl`margin-left: 15px;`};
            }
        `}
`;

// остался тут, т.к. его нельзя базировать на LocalizedLink,
// т.к. не поддерживает свойства, которые передаются в StyledLinkBasedOnLink
export const StyledLinkBasedOnLink = styled(Link)`
    ${linkCss};
`;
