class DomUtilsClass {
    public animate = (
        elem: any,
        style: any,
        unit: any,
        from: any,
        to: any,
        time: any,
        prop: any,
    ) => {
        if (!elem) {
            return;
        }
        const start = new Date().getTime();
        const timer = setInterval(() => {
            const step = Math.min(1, (new Date().getTime() - start) / time);
            if (prop) {
                elem[style] = from + step * (to - from) + unit;
            } else {
                elem.style[style] = from + step * (to - from) + unit;
            }
            if (step === 1) {
                clearInterval(timer);
            }
        }, 25);
        if (prop) {
            elem[style] = from + unit;
        } else {
            elem.style[style] = from + unit;
        }
    };
}

export const DomUtils = new DomUtilsClass();
