import { GetCountries } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_COUNTRIES = gql`
    query GetCountries {
        countries {
            id
            name
            code
            phoneCode
        }
    }
`;

export const useCountries = () => {
    const countryList = useQuery<GetCountries>(GET_COUNTRIES, {
        ssr: true,
    });

    const countries = g(countryList, "data", "countries");

    return {
        countries,
        loading: countryList.loading,
        error: countryList.error,
    };
};
